<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="0" md="4"> </v-col>
      <v-col cols="12" md="4">
        <v-card style=" border-radius: 8px; box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;">
          <v-card-text class="black--text" v-if="success">
            <div class="text-center my-10">
              <h3 class="mb-3">{{ $t("Recovery.success") }}</h3>
              <h5 class="text-sm font-600 grey--text text--darken-4">
                {{ $t("Recovery.checkEmail") }}
              </h5>
            </div>
          </v-card-text>

          <v-card-text class="black--text" v-else>
            <v-form ref="recoveryForm" class="px-5" :disabled="loading">
              <div class="text-center my-10">
                <h3 class="mb-3">{{ $t("Recovery.passwordRecovery") }}</h3>
                <h5 class="text-sm font-600 grey--text text--darken-4">
                  {{ $t("Recovery.instructions") }}
                </h5>
              </div>

              <div>
                <v-text-field v-model="username" :placeholder="$t('Recovery.username')" outlined dense :rules="[$sv.required]" />
              </div>

              <div class="mb-7">
                <v-btn block color="primary" class="text-capitalize font-600" @click="() => signup()"
                  :loading="loading">
                  {{ $t("Recovery.recovery") }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
          <v-card-text class="py-4 text-center grey lighten-2">
            <div class="grey--text text--darken-1">
              {{ $t("Signup.alreadyHave") }}
              
              <router-link class="font-600" :to="`/entities/${$route.params.entidadId}/signin`" v-text="$t('Signup.login')"></router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="0" md="4"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      checkbox: false,
      username: "",
      loading: false,
      success: false,
    };
  },
  methods: {
    signup() {
      let success = this.$refs.recoveryForm.validate();

      if (success) {
        this.loading = true;
        this.success = false;

        this.$store
          .dispatch("authentication/passwordRecovery", this.username)
          .then(() => {
            this.success = true;
            this.loading = false;
          })
          .catch((err) => {
            this.$dialog({
              title: "Error",
              text: err,
            });
            this.success = false;
            this.loading = false;
          });
      }
    }
  },
};
</script>